export const tblVars = {
  baseWebappUrl: 'https://alpha.eatable.ch',
  baseAdminUrl: 'https://admin.eatable.ch',
  baseApiUrl: 'https://api.eatable.ch',
  baseCdnUrl: 'https://cdn.eatable.ch',
}
// dev-urls {
//   baseWebappUrl: 'https://alpha.eatable.ch',
//   baseAdminUrl: 'https://admin-dev.eatable.ch',
//   baseApiUrl: 'https://api-dev.eatable.ch',
//   baseCdnUrl: 'https://cdn-dev.eatable.ch',
// }