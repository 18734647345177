
import { computed, defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import {
  heartOutline,
  personAddOutline,
  personOutline,
  radioOutline,
  restaurantOutline,
  storefrontOutline,
} from 'ionicons/icons';
import store from '@/store';
// import axios from 'axios';
// import { getCookie } from '@/utils/cookieMethods';
// import { validateUser, setUserInfo } from '@/utils/methods/userMethods';

export default defineComponent({
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    const loggedInComputed = computed(() => {
      return store.getters.getLoginState;
    });

    return {
      heartOutline,
      personAddOutline,
      personOutline,
      radioOutline,
      restaurantOutline,
      storefrontOutline,
      loggedInComputed,
    };
  },
  // mounted() {
  //   const authToken = getCookie('sessionToken');
  //   if ( authToken ) {
  //     validateUser(authToken)
  //     .then(userId => {
  //         store.dispatch('initiateUserLogin', {
  //           userId: userId,
  //           authToken: authToken
  //         });
  //         if ( !this.currentUserInfo.userName ) {
  //           setUserInfo(authToken, userId);
  //         } else {
  //           console.log( this.currentUserInfo );
  //         }
  //       })
  //     .catch(e => {
  //       console.error(e);
  //     });
  //   }
  // },
  computed: {
    currentUserInfo() {
      return store.getters.getUserInfo;
    },
  },
});
